export namespace GatewayEvents {
	export class FetchForGateway {
		static readonly type = '[GatewayEvents] Fetch all for gateway';
		constructor(public gateway_id: any) { }
	}

	export class SetFilter {
		static readonly type = '[GatewayEvents] Set Filter';
		constructor(public gateway_id: number, public filter: any) { }
	}
}