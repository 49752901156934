<aside class="fixed top-0 left-0 z-20 w-64 h-screen pt-14 transition-transform bg-gray translate-x-0 border-r border-secondary" aria-label="Sidenav"
	id="drawer-navigation">
	<div class="overflow-y-auto py-5 px-3 h-full bg-gray">
		<div class="px-3 mb-6">
			<span class="opacity-70 font-normal text-sm text-primary">Organization:</span>
			<h1 class="font-bold text-lg text-primary break-words">{{ ($organization | async) ? ($organization | async)?.name : '-' }}</h1>
		</div>
		<ul class="space-y-2">
			<li>
				<button pButton routerLink="dashboard" routerLinkActive #rlaDashbaord="routerLinkActive" class="w-full text-left"
					[ngClass]="{'': rlaDashbaord.isActive ,'p-button-text': !rlaDashbaord.isActive }">Dashboard</button>
			</li>
			<li>
				<button pButton routerLink="gateways" routerLinkActive #rlaGateways="routerLinkActive" class="w-full text-left"
					[ngClass]="{'': rlaGateways.isActive ,'p-button-text': !rlaGateways.isActive }">Gateways</button>
			</li>
			<li>
				<button pButton routerLink="groups" routerLinkActive #rlaGroups="routerLinkActive" class="w-full text-left"
					[ngClass]="{'': rlaGroups.isActive ,'p-button-text': !rlaGroups.isActive }">Groups</button>
			</li>
			<li>
				<button pButton routerLink="rules" routerLinkActive #rlaRules="routerLinkActive" class="w-full text-left"
					[ngClass]="{'': rlaRules.isActive ,'p-button-text': !rlaRules.isActive }">Rules</button>
			</li>
		</ul>
		<ul class="space-y-2 pt-2 mt-2 border-t border-secondary">
			<li>
				<button pButton routerLink="organization" routerLinkActive #rlaOrganization="routerLinkActive" class="w-full text-left"
					[ngClass]="{'': rlaOrganization.isActive ,'p-button-text': !rlaOrganization.isActive }">Organization</button>
			</li>
		</ul>
	</div>
	<div>
		<p-button (onClick)="reset()">Reset Store</p-button>
	</div>
	<div class="absolute bottom-0 left-0 justify-center p-4 space-x-4 w-full lg:flex bg-gray z-20">
		<span class="text-xs opacity-50">version {{version}}</span>
	</div>
</aside>