import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { environment } from '@env/environment';

import { Select, Store } from '@ngxs/store';
import { OrganizationState } from '../../state/organization/organization.state';
import { Observable } from 'rxjs';
import { Organization } from '../../state/organization/organization.model';
import { UserState } from '../../state/user/user.state';
import { User } from '../../models/user/user.model';


@Injectable({
	providedIn: 'root'
})
export class GroupsService {

	superadmin_prefix: string = '';
	@Select(OrganizationState.getSelecetdOrganization) $organization: Observable<Organization>;
	@Select(UserState.getUser) $user: Observable<User>;

	constructor(private apiService: ApiService, private store: Store) {
		this.$user.subscribe((user: User) => {
			if (!user || Object.keys(user).length == 0) return;

			console.log('user', user);
			if (user.role.id == 1) {
				this.$organization.subscribe((organization: Organization) => {
					this.superadmin_prefix = `/organizations/${organization.id}`;
				});
			}
		});
	}

	fetchGroups(options?: any) {
		return this.apiService.get(`${environment.api_base_url}${this.superadmin_prefix}/groups`, options);
	}

	fetchGroupsList(options?: any) {
		return this.apiService.get(`${environment.api_base_url}${this.superadmin_prefix}/groups/list`, options);
	}

	deleteGroup(group_id: number, options?: any) {
		return this.apiService.delete(`${environment.api_base_url}${this.superadmin_prefix}/groups/${group_id}`, options);
	}

	addGroup(group: any, options?: any) {
		return this.apiService.post(`${environment.api_base_url}${this.superadmin_prefix}/groups`, group, options);
	}

	updateGroup(group_id: number, group: any, options?: any) {
		return this.apiService.put(`${environment.api_base_url}${this.superadmin_prefix}/groups/${group_id}`, group, options);
	}

	fetchGroup(group_id: number, options?: any) {
		return this.apiService.get(`${environment.api_base_url}${this.superadmin_prefix}/groups/${group_id}`, options);
	}

	fetchGroupGateways(group_id: number, options?: any) {
		return this.apiService.get(`${environment.api_base_url}${this.superadmin_prefix}/groups/${group_id}/gateways`, options);
	}

	fetchGroupGatewaysList(group_id: number, options?: any) {
		return this.apiService.get(`${environment.api_base_url}${this.superadmin_prefix}/groups/${group_id}/gateways/list`, options);
	}

	assignGroups(group_id: number) {

	}
}
