import { Injectable } from "@angular/core";
import { HttpParams } from '@angular/common/http';

import { Action, Selector, StateContext, State } from "@ngxs/store";
import { patch } from "@ngxs/store/operators";
import { tap, finalize, catchError } from "rxjs";

import { HandleApiError } from "../api_error/api_error.actions";

import { RulesService } from "../../services/rules/rules.service";
import { Rules } from "./rule.actions";
import { Rule } from "../../models/rule/rule.model";

export class RuleStateModel {
	filter: any
	rules: any
	loading: boolean
}

@State<RuleStateModel>({
	name: 'rulestate',
	defaults: {
		filter: {
			page: '',
			per_page: '',
			q: '',
			total: ''
		},
		rules: [],
		loading: false
	}
})

@Injectable()
export class RuleState {
	constructor(private rulesService: RulesService) { }

	@Selector()
	static selectRules(state: RuleStateModel) {
		return state.rules;
	}

	@Selector()
	static getRule(state: RuleStateModel) {
		return (id: number) => {
			return state.rules.find((r: Rule) => {
				return r.id == id
			});
		};
	}

	@Selector()
	static filter(state: RuleStateModel): string {
		return state.filter;
	}

	@Selector()
	static loading(state: RuleStateModel) {
		return state.loading;
	}

	@Action(Rules.SetFilter)
	setFilter(ctx: StateContext<RuleStateModel>, action: Rules.SetFilter) {
		return ctx.setState(patch<RuleStateModel>({
			filter: action.filter
		}));
	}

	@Action(Rules.FetchAll)
	getRules(ctx: StateContext<RuleStateModel>) {
		const state = ctx.getState();
		let filter: any = state.filter ? { ...state.filter } : {};
		const options = {
			params: new HttpParams({
				fromObject: filter
			})
		}
		ctx.patchState({ loading: true });

		return this.rulesService.fetchRules(options).pipe(tap((returnData: any) => {
			console.log('fetchRules', returnData);
			const state = ctx.getState();
			ctx.patchState({
				...state,
				rules: returnData.data,
				filter: {
					...state.filter,
					page: returnData.current_page,
					per_page: returnData.per_page,
					total: returnData.total
				},
				loading: false
			});
		}), catchError((error: any) => {
			return ctx.dispatch(new HandleApiError(error));
		}), finalize(() => {
			ctx.patchState({
				loading: false
			});
		}));
	}


}