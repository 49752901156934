import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-test',
	templateUrl: './test.component.html',
	styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {


	tableData: any;
	tableColumns: any;

	public chartData: any;


	buttonStyles: string[] = ['outline', 'solid'];

	filterButtonStyleState: boolean = true;
	exportButtonStyleState: boolean = false;

	constructor() { }

	ngOnInit(): void {

		this.tableData = [
			{ gateway_id: 1, type: 'SmartThings HUB', online: 0, date: 'Wednesday, July 5, 2023 at 2:00:32 PM GMT+02:00' },
			{ gateway_id: 1, type: 'SmartThings HUB', online: 0, date: 'Wednesday, July 5, 2023 at 2:00:32 PM GMT+02:00' },
			{ gateway_id: 1, type: 'SmartThings HUB', online: 0, date: 'Wednesday, July 5, 2023 at 2:00:32 PM GMT+02:00' },
			{ gateway_id: 1, type: 'SmartThings HUB', online: 0, date: 'Wednesday, July 5, 2023 at 2:00:32 PM GMT+02:00' },
			{ gateway_id: 1, type: 'SmartThings HUB', online: 0, date: 'Wednesday, July 5, 2023 at 2:00:32 PM GMT+02:00' }
		]

		this.tableColumns = [
			{
				columnDef: 'gateway_id',
				header: 'Gateway ID',
				isSortable: true
			},
			{
				columnDef: 'type',
				header: 'Type',
				isSortable: true
			},
			{
				columnDef: 'online',
				header: 'Sate',
				isSortable: true
			},
			{
				columnDef: 'date',
				header: 'UPDATE DATE',
				isSortable: true
			},
			{
				columnDef: 'action',
				header: 'Action'
			}
		]


		this.chartData = [{
			type: 'line', //this denotes tha type of chart
			data: {// values on X-Axis
				labels: ['2022-05-10', '2022-05-11', '2022-05-12', '2022-05-13',
					'2022-05-14', '2022-05-15', '2022-05-16', '2022-05-17',],
				datasets: [
					{
						label: "Sales",
						data: ['467', '576', '572', '79', '92',
							'574', '573', '576'],
						borderColor: '#FE8787',
						backgroundColor: '#FFDEDE',
						fill: true
					},
				]
			},
			options: {
				responsive: true,
				aspectRatio: 5,
				maintainAspectRatio: false,
				plugins: {
					legend: {
						display: false
					}
				},
				elements: {
					line: {
						tension: 0.4
					}
				},
				scales: {
					x: {
						display: false
					},
					y: {
						display: false
					}
				}
			}
		}, {
			type: 'line', //this denotes tha type of chart
			data: {// values on X-Axis
				labels: ['2022-05-10', '2022-05-11', '2022-05-12', '2022-05-13', '2022-05-14', '2022-05-15', '2022-05-16', '2022-05-17',],
				datasets: [
					{
						label: "Sales",
						data: [0, 1, 1, 1, 0, 0, 1, 1],
						borderColor: '#4608F6',
						backgroundColor: '#9470FB',
						steppedLine: true,
						stepped: 'before',
						fill: true
					},
				]
			},
			options: {
				responsive: true,
				aspectRatio: 5,
				maintainAspectRatio: false,
				// interaction: {
				// 	intersect: false,
				// 	axis: 'x'
				// },
				plugins: {
					legend: {
						display: false
					}
				},
				// elements: {
				// 	line: {
				// 		tension: 0
				// 	}
				// },
				scales: {
					x: {
						display: false,
						ticks: {
							beginAtZero: true,
							stepSize: 1
						}
					},
					y: {
						display: false
					}
				}
			}
		}];
	}

	openModal(id: string) {

	}

	panic() {
		alert('ALARMMMMM!!!!');
	}

	buttonStyle(state: boolean) {
		const index = state ? 1 : 0;
		console.log(' this.buttonStyles[index];', this.buttonStyles[index]);
		return this.buttonStyles[index];
	}

	toggleFilter() {
		this.filterButtonStyleState = !this.filterButtonStyleState;
		console.log(this.filterButtonStyleState);
	}

}
