import { Injectable } from '@angular/core';
import { Subject, EMPTY, of } from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { catchError, delay, switchAll, switchMap, tap } from 'rxjs/operators';

import { ApiService } from '../api/api.service';
import { environment } from '@env/environment';

@Injectable({
	providedIn: 'root'
})
export class WebsocketService {
	$ws: WebSocketSubject<any> | undefined;

	retrys: number = 5;
	retry: number = 0;
	delay: number = 5000;

	private $messagesSubject = new Subject();
	public $messages = this.$messagesSubject.pipe(switchAll() as any, catchError(e => { throw e }));

	constructor(private apiService: ApiService) { }

	connect(reconnect?: boolean): void {
		if (this.$ws && !this.$ws.closed) return;

		if (reconnect && this.retry == this.retrys) {
			return;
		} else if (reconnect) {
			this.retry++;
			console.log('[WebsocktService] retry connection ', `${this.retry} of ${this.retrys}`);
		}

		this.apiService.get(`${environment.api_base_url}/auth/websocket`).pipe(reconnect ? delay(this.delay) : o => o, switchMap((response: any) => {
			console.log('response', response);
			const url = `${environment.ws_url}/?token=${response.token}`;

			this.$ws = this.createWebSocket(url);

			const message = this.$ws.pipe(
				tap({
					error: error => console.error('[WebsocktService]', error)
				}),
				catchError(_ => EMPTY)
			);

			this.$messagesSubject.next(message);

			return of(response);
		})).subscribe();
	}

	createWebSocket(url: string) {
		return webSocket({
			url: url,
			openObserver: {
				next: () => {
					console.log('[WebsocktService]: connection ok');
					this.retry = 0;
				}
			},
			closeObserver: {
				next: (error) => {
					console.log(error);
					console.log('[WebsocktService]: connection closed');
					this.$ws = undefined;
					if (![1000, 1005, 1006].includes(error.code)) {
						this.connect(true) // reconnect with delay
					}
				}
			}
		});
	}

	close() {
		if (this.$ws && !this.$ws.closed) {
			//this.$ws.complete();
			this.$ws.error({ code: 1000 })
			this.$ws = undefined;
		}
	}

	send(data: any) {
		if (this.$ws && !this.$ws.closed) {
			this.$ws.next(data);
		} else {
			console.error('No connection open');
		}
	}
}