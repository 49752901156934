import { Injectable } from "@angular/core";
import { Action, Selector, StateContext, State, createSelector } from "@ngxs/store";
import { GatewayService } from "../../services/gateway/gateway.service";
import { DeviceEvents } from "./device_events.actions";
import { tap, finalize } from "rxjs";
import { DeviceEvent } from "../../models/device_event/device_event.model";
import { HttpParams } from '@angular/common/http';

export class DeviceEventEntry {
	filter: {
		page: number,
		per_page: number,
		q: string,
		total: number,
		s: []
	}
	events: DeviceEvent[]
}

export class DeviceEventsStateModel {
	device_events: { [key: string]: DeviceEventEntry }
	loading: boolean
}

@State<DeviceEventsStateModel>({
	name: 'deviceeventsstate',
	defaults: {
		device_events: {},
		loading: false
	}
})

@Injectable()
export class DeviceEventsState {
	constructor(private gatewayService: GatewayService) { }

	@Selector()
	static filter(state: DeviceEventsStateModel) {
		return (device_id: number) => {
			const filter = state.device_events[device_id] ? state.device_events[device_id].filter : {
				page: 0,
				per_page: 0,
				q: '',
				total: 0,
				s: []
			};
			return filter;
		};
	}

	@Selector()
	static getDeviceEvents(state: DeviceEventsStateModel) {
		return (gateway_id: number) => {
			let events: any = state.device_events[gateway_id] ? state.device_events[gateway_id].events : [];
			return events;
		}
	}

	@Selector()
	static loading(state: DeviceEventsStateModel) {
		return state.loading;
	}

	@Action(DeviceEvents.FetchForDevice)
	getDeviceEvents(ctx: StateContext<DeviceEventsStateModel>, action: DeviceEvents.FetchForDevice) {
		const state = ctx.getState();

		let filter: any = state.device_events[action.device_id] ? state.device_events[action.device_id].filter : {};

		filter = {
			...filter,
			s: filter.s ? filter.s.join(',') : []
		}

		const options = {
			params: new HttpParams({
				fromObject: filter
			})
		}

		ctx.patchState({ loading: true });

		return this.gatewayService.fetchEventsForGatewayDevice(action.gateway_id, action.device_id, options).pipe(tap((returnData: any) => {
			console.log('FetchForDevice', returnData);
			const events = returnData.data;

			const state = ctx.getState();
			ctx.patchState({
				...state,
				device_events: {
					...state.device_events,
					[action.device_id]: {
						events: events,
						filter: {
							page: returnData.current_page,
							per_page: returnData.per_page,
							total: returnData.total,
							q: ''
						}
					}
				}
			});
		}), finalize(() => {
			ctx.patchState({
				loading: false
			});
		}))
	}

	@Action(DeviceEvents.SetFilter)
	setFilter(ctx: StateContext<DeviceEventsStateModel>, action: DeviceEvents.SetFilter) {
		const state = ctx.getState();
		const device_events = state.device_events[action.device_id];
		console.log('device_events', device_events);
		return ctx.patchState({
			...state,
			device_events: {
				...state.device_events,
				[action.device_id]: {
					...device_events,
					filter: {
						...device_events.filter,
						...action.filter
					}
				}
			}
		});
	}

}