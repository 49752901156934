import { Directive, Input, ElementRef, HostListener, HostBinding } from '@angular/core';

@Directive({
	selector: '[appOnHold]'
})
export class OnHoldDirective {
	timeout: any;

	protected _elementClass: string[] = [];

	@Input() appOnHold: Function;
	@Input() duration: any = 2000;

	constructor(private el: ElementRef) { }

	@HostBinding('class')
	get elementClass(): string {
		return this._elementClass.join(' ');
	}
	set(val: string) {
		this._elementClass = val.split(' ');
	}

	@HostListener('mousedown') onMouseDown() {
		console.log('Button pressed');
		this._elementClass.push('circle');
		// this._elementClass.push(`duration-[${this.duration}]`);

		this.timeout = setTimeout(() => {
			if (typeof this.appOnHold == 'function') {
				console.log('Action called');
				this.appOnHold();
				this.removeClass('circle');
			};
		}, this.duration);
	}

	@HostListener('mouseup') onMouseUp() {
		console.log('Button released');
		this.removeClass('circle');
		// this.removeClass(`duration-[${this.duration}]`);

		if (this.timeout) {
			clearTimeout(this.timeout);
		}
	}

	removeClass(cls: string) {
		const index = this._elementClass.indexOf(cls);
		this._elementClass.splice(index, 1);
	}
}
