export namespace GatewayDevicesGraphs {
	export class FetchForGatewayDevice {
		static readonly type = '[GatewayDevicesGraphs] Fetch all for gateway device';
		constructor(public gateway_id: any, public device_id: any) { }
	}

	export class SetFilter {
		static readonly type = '[GatewayDevicesGraphs] Set Filter';
		constructor(public gateway_id: number, public device_id: number, public filter: any) { }
	}

}