import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, of } from 'rxjs';
import { Store } from '@ngxs/store';
import { HandleApiError } from '../../state/api_error/api_error.actions';

@Injectable({
	providedIn: 'root'
})
export class ApiService {

	constructor(private http: HttpClient, private store: Store) { }

	public get(url: string, options?: any) {
		return this.http.get(url, options).pipe(catchError(
			(error: any) => {
				this.store.dispatch(new HandleApiError(error));
				return of(error);
			}
		));
	}

	public post(url: string, data: any, options?: any) {
		return this.http.post(url, data, options).pipe(catchError(
			(error: any) => {
				this.store.dispatch(new HandleApiError(error));
				return error;
			}
		));
	}

	public put(url: string, data: any, options?: any) {
		return this.http.put(url, data, options).pipe(catchError(
			(error: any) => {
				this.store.dispatch(new HandleApiError(error));
				return error;
			}
		));
	}

	public delete(url: string, options?: any) {
		return this.http.delete(url, options).pipe(catchError(
			(error: any) => {
				this.store.dispatch(new HandleApiError(error));
				return error;
			}
		));
	}
}
