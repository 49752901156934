import { Injectable } from "@angular/core";
import { HttpParams } from '@angular/common/http';

import { Action, Selector, StateContext, State } from "@ngxs/store";
import { patch } from "@ngxs/store/operators";
import { tap, finalize, catchError } from "rxjs";
import { HandleApiError } from "../api_error/api_error.actions";

import { OrganizationService } from "src/app/shared/services/organization/organization.service";
import { OrganizationUsers } from "./organization_users.actions";
import { OrganizationUser } from "./organization_users.model";

export class OrganizationUsersStateModel {
    filter: any
    organization_users: OrganizationUser[]
    loading: boolean
}

@State<OrganizationUsersStateModel>({
    name: 'organizationusersstate',
    defaults: {
        filter: {
            page: '',
            per_page: '',
            q: '',
            f: '',
            total: ''
        },
        organization_users: [] as OrganizationUser[],
        loading: false
    }
})

@Injectable()
export class OrganizationUsersState {
    constructor(private organizationService: OrganizationService) { }

    @Selector()
    static currentPage(state: OrganizationUsersStateModel): number {
        return state.filter.page;
    }

    @Selector()
    static itemsOnPage(state: OrganizationUsersStateModel): number {
        return state.filter.per_page;
    }

    @Selector()
    static query(state: OrganizationUsersStateModel): string {
        return state.filter.q;
    }

    @Selector()
    static total(state: OrganizationUsersStateModel): string {
        return state.filter.total;
    }

    @Selector()
    static filter(state: OrganizationUsersStateModel): string {
        return state.filter;
    }

    @Selector()
    static loading(state: OrganizationUsersStateModel) {
        return state.loading;
    }

    @Selector()
    static getOrganizationUsers(state: OrganizationUsersStateModel) {
        return state.organization_users;
    }

    @Action(OrganizationUsers.SetFilter)
    setFilter(ctx: StateContext<OrganizationUsersStateModel>, action: OrganizationUsers.SetFilter) {
        return ctx.setState(patch<OrganizationUsersStateModel>({
            filter: action.filter
        }));
    }

    @Action(OrganizationUsers.FetchAll)
    getOrganizationUsers(ctx: StateContext<OrganizationUsersStateModel>) {
        const state = ctx.getState();
        let filter: any = state.filter ? { ...state.filter } : {};
        const options = {
            params: new HttpParams({
                fromObject: filter
            })
        }
        ctx.patchState({ loading: true });
        return this.organizationService.fetchOrganizationUsers(options).pipe(tap((returnData: any) => {
            console.log('users', returnData);
            const state = ctx.getState();
            let users: OrganizationUser[];
            if (Array.isArray(returnData.data)) {
                users = returnData.data;
            } else {
                users = [returnData.data];
            }

            ctx.patchState({
                ...state,
                organization_users: users,
                filter: {
                    ...state.filter,
                    page: returnData.current_page,
                    per_page: returnData.per_page,
                    total: returnData.total,
                    q: ''
                }
            });
        }), finalize(() => {
            ctx.patchState({
                loading: false
            });
        }));
    }
}
