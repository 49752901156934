import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Observable, tap, map, of } from 'rxjs';
import { Store, Select } from '@ngxs/store';

import { Chart } from 'chart.js/auto';

import { GatewaysEvents } from '../shared/state/gateways_events/gateways_events.actions';
import { GatewaysEventsState } from '../shared/state/gateways_events/gateways_events.state';
import { Event } from '../shared/models/event/event.model';
import { OrganizationState } from '../shared/state/organization/organization.state';
import { Organization } from '../shared/state/organization/organization.model';
import { Organization as OrganizationAction } from '../shared/state/organization/organization.actions';

import { PaginatorState } from 'primeng/paginator';
import { SortMeta } from 'primeng/api';
import { Table, TableLazyLoadEvent } from 'primeng/table';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
	chart: any;
	chart2: any;

	organization: any;

	$gatewaysEventsTableData: Observable<any>;
	columns: any;

	first: number = 0;
	filter: any;
	rowsPerPageOptions: number[] = [10, 25, 50, 100];

	term: string;
	order: number;

	@ViewChild('table') table: Table;

	@Select(GatewaysEventsState.selectGatewayEvents) $gatewaysEvents: Observable<Event[]>;
	@Select(GatewaysEventsState.filter) $filter: Observable<any>;
	@Select(GatewaysEventsState.loading) $loading: Observable<any>;
	@Select(OrganizationState.getSelecetdOrganization) $organization: Observable<Organization>;

	constructor(private store: Store) { }

	ngOnInit(): void {

		this.store.dispatch(new GatewaysEvents.SetFilter({ per_page: 10, page: 1, q: '', total: '' }))
		this.store.dispatch(new GatewaysEvents.FetchAll);

		this.$filter.subscribe((data) => {
			if (!data) return;
			this.filter = data;
			this.first = (data.page - 1) * data.per_page;
		});

		this.columns = [
			{
				field: 'gateway',
				header: 'Gateway',
				order: 0
			},
			{
				field: 'device',
				header: 'Device',
				order: 0
			},
			{
				field: 'old_value',
				header: 'Old Value',
				order: 0
			},
			{
				field: 'new_value',
				header: 'New Value',
				order: 0
			},
			{
				field: 'type',
				header: 'Type',
				order: 0
			},
			{
				field: 'created_at',
				header: 'Created at date',
				order: 0
			}
		];

		const plugin = {
			id: 'emptyDoughnut',
			afterDraw(chart: any, args: any, options: any) {
				if (chart.config.type != 'doughnut') {
					return;
				}

				const { datasets } = chart.data;
				const { color, width, radiusDecrease } = options;
				let hasData = false;

				for (let i = 0; i < datasets.length; i += 1) {
					const dataset = datasets[i];
					hasData = dataset.data.length > 0;
				}

				if (!hasData) {
					const defaultCutout = 0.6;
					let cutout = defaultCutout;
					if (chart.config.options.cutout) {
						let y = parseFloat(chart.config.options.cutout);
						if (!isNaN(y)) {
							cutout = y / 100;
						}
					}

					const { chartArea: { left, top, right, bottom }, ctx } = chart;
					const centerX = (left + right) / 2;
					const centerY = (top + bottom) / 2;
					const r = Math.min(right - left, bottom - top) / 2;

					ctx.beginPath();
					ctx.lineWidth = r * (1 - cutout);
					ctx.strokeStyle = color || 'rgba(255, 128, 0, 0.5)';
					ctx.arc(centerX, centerY, r - ((r * (1 - cutout)) / 2), 0, 2 * Math.PI);
					ctx.stroke();
				}
			}
		};

		Chart.register(plugin);

		this.$gatewaysEventsTableData = this.$gatewaysEvents.pipe(map((events: Event[]) => !events ? [] : events.map((e: Event) => {

			return {
				id: e.gateway.id,
				gateway: e.gateway.name ? e.gateway.name : e.gateway.third_party.name,
				device: e.device && e.device.name ? e.device.name : (e.device && e.device.third_party && e.device.third_party.name ? e.device.third_party.name : '-'),
				new_value: e.new_value ?? '-',
				old_value: e.old_value ?? '-',
				type: e.type ? `${e.type.target}_${e.type.event}` : '-',
				created_at: e.created_at ?? '-'
			}
		})));

		this.$organization.pipe(tap((organization: Organization) => {
			this.store.dispatch(new GatewaysEvents.FetchAll());
		})).subscribe((organization: Organization) => {
			if (!organization) return;

			this.organization = organization;

			let gatewayData: any[] = [];
			console.log('this.organization', this.organization);

			if (Object.keys(this.organization).length != 0) {
				gatewayData = this.organization.statistics.gateways.total == 0 ? [] : [this.organization.statistics.gateways.total - this.organization.statistics.gateways.online, this.organization.statistics.gateways.online]
			}

			const chartData: any = {
				type: 'doughnut',
				data: {
					labels: [
						'Offline',
						'Online'
					],
					datasets: [{
						data: gatewayData,
						backgroundColor: [
							'rgb(255, 0, 0)',
							'rgb(0, 255, 0)'
						],
						hoverOffset: 4
					}]
				},
				options: {
					cutout: '60%',
					responsive: true,
					aspectRatio: 1,
					maintainAspectRatio: false,
					plugins: {
						legend: {
							display: false
						},
						emptyDoughnut: {
							color: 'rgb(241, 245, 249)',
							width: 25,
							radiusDecrease: 15
						}
					}
				}
			};

			if (this.chart) {
				this.chart.clear();
				this.chart.destroy();
				this.chart = null;
			}

			this.chart = new Chart('online_state_chart', chartData);


			let devicesData: any[] = [];
			if (Object.keys(this.organization).length != 0) {
				devicesData = this.organization.statistics.devices.total == 0 ? [] : [this.organization.statistics.devices.total - this.organization.statistics.devices.online, this.organization.statistics.devices.online]
			}
			const deviceOnlineStateChartData: any = {
				type: 'doughnut',
				data: {
					labels: [
						'Offline',
						'Online'
					],
					datasets: [{
						data: devicesData,
						backgroundColor: [
							'rgb(255, 0, 0)',
							'rgb(0, 255, 0)'
						],
						hoverOffset: 4
					}]
				},
				options: {
					cutout: '60%',
					responsive: true,
					aspectRatio: 1,
					maintainAspectRatio: false,
					plugins: {
						legend: {
							display: false
						},
						emptyDoughnut: {
							color: 'rgb(241, 245, 249)',
							width: 25,
							radiusDecrease: 15
						}
					}
				}
			};

			if (this.chart2) {
				this.chart2.clear();
				this.chart2.destroy();
				this.chart2 = null;
			}

			this.chart2 = new Chart('device_online_state_chart', deviceOnlineStateChartData);
		});
	}

	ngOnDestroy(): void {
		this.chart.destroy();
		this.chart2.destroy();
	}

	onSearchEvent() {
		this.store.dispatch(new GatewaysEvents.SetFilter({ ...this.filter, q: this.term }));
		this.store.dispatch(new GatewaysEvents.FetchAll());
	}

	onPageChange(event: PaginatorState) {
		console.log(event);
		this.first = event.first as number;
		const page: number = event.page ? event.page + 1 : 1;

		this.store.dispatch(new GatewaysEvents.SetFilter({ ...this.filter, page: page, per_page: event.rows }));
		this.store.dispatch(new GatewaysEvents.FetchAll());
	}

	sortChanged(event: TableLazyLoadEvent) {
		console.log(event);
		let s: any[] = [];

		if (event.multiSortMeta) {
			event.multiSortMeta?.filter((field: SortMeta) => field.order != 0).forEach((field: SortMeta) => {
				const order = field.order == -1 ? 'asc' : 'desc';
				s.push(`${field.field}.${order}`);
			});
		} else {
			const columIndex = this.columns.findIndex((column: any) => column.field = event.sortField);

			if (columIndex == -1) return;

			if (this.columns[columIndex].order == -1 && event.sortOrder) {
				this.columns[columIndex].order = 0;
				this.table.reset();

			} else {
				this.columns[columIndex].order = event.sortOrder;
			}

			const order = this.columns[columIndex].order == 0 ? 'default' : this.columns[columIndex].order == -1 ? 'asc' : 'desc';
			s.push(`${event.sortField}.${order}`);
		}

		this.store.dispatch(new GatewaysEvents.SetFilter({ ...this.filter, s: s }));
		this.store.dispatch(new GatewaysEvents.FetchAll());
	}

	reloadGatewaysEvents() {
		this.store.dispatch(new GatewaysEvents.FetchAll());
	}

}
