import { Injectable } from "@angular/core";
import { HttpParams } from "@angular/common/http";
import { Action, Selector, StateContext, State, Store } from "@ngxs/store";
import { patch } from "@ngxs/store/operators";

import { finalize, tap } from "rxjs";
import { GroupsService } from "../../services/groups/groups.service";
import { Groups } from "./groups.actions";

export class GroupsStateModel {
	groups: any
	filter: any
	loading: boolean
}

@State<GroupsStateModel>({
	name: 'groupsstate',
	defaults: {
		groups: [],
		filter: {
			page: '',
			per_page: '',
			q: '',
			total: '',
			s: []
		},
		loading: false
	}
})

@Injectable()
export class GroupsState {
	constructor(private groupsService: GroupsService, private store: Store) { }

	@Selector()
	static loading(state: GroupsStateModel) {
		return state.loading;
	}

	@Selector()
	static getGroups(state: GroupsStateModel) {
		return state.groups ? state.groups : [];
	}

	@Selector()
	static filter(state: GroupsStateModel): string {
		return state.filter;
	}

	@Selector()
	static getGroup(state: GroupsStateModel) {
		return (id: number) => {
			return state.groups.find((g: any) => {
				return g.id == id
			});
		};
	}

	@Action(Groups.SetFilter)
	setFilter(ctx: StateContext<GroupsStateModel>, action: Groups.SetFilter) {
		return ctx.setState(patch<GroupsStateModel>({
			filter: action.filter
		}));
	}

	@Action(Groups.FetchGroups)
	async getGroups(ctx: StateContext<GroupsStateModel>, action: Groups.FetchGroups) {

		const state = ctx.getState();
		let filter: any = state.filter ? { ...state.filter } : {};

		delete filter.total;

		filter = {
			...filter,
			s: filter.s ? filter.s.join(',') : []
		}

		Object.keys(filter).forEach(key => (filter[key] === undefined || filter[key] === null) && delete filter[key]);

		const options = {
			params: new HttpParams({
				fromObject: filter
			})
		}
		ctx.patchState({ loading: true });

		return this.groupsService.fetchGroups(options).pipe(
			tap((returnData: any) => {
				console.log('returnData events', returnData);
				ctx.patchState({
					...state,
					groups: returnData.data,
					filter: {
						page: returnData.current_page,
						per_page: returnData.per_page,
						total: returnData.total
					}
				});
			}), finalize(() => {
				ctx.patchState({
					loading: false
				});
			})
		);
	}
}