import { Component, OnInit } from '@angular/core';

import { environment } from '@env/environment';
import { Store, Select } from '@ngxs/store';
import { Organization } from 'src/app/shared/state/organization/organization.model';
import { OrganizationState } from 'src/app/shared/state/organization/organization.state';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

	version: string = environment.version;

	@Select(OrganizationState.getSelecetdOrganization) $organization: Observable<Organization>;

	constructor(
		private store: Store) { }

	ngOnInit(): void {

	}

	reset(): void {
		this.store.reset({});
	}

}
