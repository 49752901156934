import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AuthGuard } from './auth.guard';
import { TestComponent } from './test/test.component';
import { ProfileSettingsComponent } from './profile-settings/profile-settings.component';

const routes: Routes = [
	{
		path: '', redirectTo: '/dashboard', pathMatch: 'full', data: { breadcrumb: 'Dashboard' }
	},
	{
		path: 'dashboard', component: DashboardComponent, data: { breadcrumb: { skip: true } }, canActivate: [AuthGuard],
	},
	{
		path: 'gateways', loadChildren: () => import('./gateways/gateways.module').then(m => m.GatewaysModule), canActivate: [AuthGuard]
	},
	{
		path: 'organization', loadChildren: () => import('./organization/organization.module').then(m => m.OrganizationModule), canActivate: [AuthGuard]
	},
	{
		path: 'rules', loadChildren: () => import('./rules/rules.module').then(m => m.RulesModule), canActivate: [AuthGuard]
	},
	{
		path: 'profile', component: ProfileSettingsComponent, canActivate: [AuthGuard]
	},
	{
		path: 'groups', loadChildren: () => import('./groups/groups.module').then(m => m.GroupsModule), canActivate: [AuthGuard]
	},
	{
		path: 'test', component: TestComponent
	},
	{
		path: '**', component: PageNotFoundComponent
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes)
	],
	exports: [RouterModule]
})
export class AppRoutingModule { }
