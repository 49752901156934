export namespace Organization {
	export class FetchAll {
		static readonly type = '[Organization] Fetch all';
	}

	export class SetFilter {
		static readonly type = '[Organization] Set Filter';
		constructor(public filter: any) { }
	}

	export class SelectOrganization {
		static readonly type = '[Organization] Select Organization';
		constructor(public id: any) { }
	}

	export class ResetState {
		static readonly type = '[Organization] Reset Organization';
		constructor() { }
	}
}