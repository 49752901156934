import { Injectable } from "@angular/core";
import { HttpParams } from '@angular/common/http';

import { Action, Selector, StateContext, State } from "@ngxs/store";
import { patch } from "@ngxs/store/operators";
import { tap, finalize, catchError } from "rxjs";
import { HandleApiError } from "../api_error/api_error.actions";

import { OrganizationService } from "src/app/shared/services/organization/organization.service";
import { Organization } from "./organization.actions";
import { Organization as OrganizationModel } from "./organization.model";

export class OrganizationStateModel {
	filter: any
	organizations: OrganizationModel[]
	organization: OrganizationModel
	loading: boolean
}

@State<OrganizationStateModel>({
	name: 'organizationstate',
	defaults: {
		filter: {
			page: '',
			per_page: '',
			q: '',
			total: '' // raus
		},
		organizations: [] as OrganizationModel[],
		organization: {} as OrganizationModel,
		loading: false
	}
})

@Injectable()
export class OrganizationState {
	constructor(private organizationService: OrganizationService) { }

	@Selector()
	static currentPage(state: OrganizationStateModel): number {
		return state.filter.page;
	}

	@Selector()
	static itemsOnPage(state: OrganizationStateModel): number {
		return state.filter.per_page;
	}

	@Selector()
	static query(state: OrganizationStateModel): string {
		return state.filter.q;
	}

	@Selector()
	static total(state: OrganizationStateModel): string {
		return state.filter.total;
	}

	@Selector()
	static filter(state: OrganizationStateModel): string {
		return state.filter;
	}

	@Selector()
	static getOrganizations(state: OrganizationStateModel) {
		return state.organizations;
	}

	@Selector()
	static getSelecetdOrganization(state: OrganizationStateModel) {
		return state.organization;
	}

	@Action(Organization.SetFilter)
	setFilter(ctx: StateContext<OrganizationStateModel>, action: Organization.SetFilter) {
		return ctx.setState(patch<OrganizationStateModel>({
			filter: action.filter
		}));
	}

	@Action(Organization.FetchAll)
	fetchOrganizations(ctx: StateContext<OrganizationStateModel>) {
		const state = ctx.getState();
		let filter: any = state.filter ? { ...state.filter } : {};
		const options = {
			params: new HttpParams({
				fromObject: filter
			})
		}

		console.log('Organization', state);
		ctx.patchState({ loading: true });
		return this.organizationService.fetchOrganizations(options).pipe(tap((returnData: any) => {
			ctx.patchState({
				...state,
				organizations: returnData.data,
				filter: {
					...state.filter,
					page: returnData.current_page,
					per_page: returnData.per_page,
					total: returnData.total,
					q: ''
				}
			});
		}), finalize(() => {
			ctx.patchState({
				loading: false
			});
		}));
	}


	@Action(Organization.SelectOrganization)
	selectOrganization(ctx: StateContext<OrganizationStateModel>, action: Organization.SelectOrganization) {
		const state = ctx.getState();
		console.log('sate.orgnization', state, action);
		const organization = state.organizations.find((organization: OrganizationModel) => organization.id == action.id);
		console.log('found', organization)
		ctx.patchState({
			...state,
			organization: organization
		});
	}

	@Action(Organization.ResetState)
	resetState(ctx: StateContext<OrganizationStateModel>) {
		ctx.setState({} as OrganizationStateModel);
	}

}
