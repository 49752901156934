import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from '@env/environment';

export const oauthConfig: AuthConfig = {
	issuer: 'https://oauth.cloud.aeotec.com/',
	// URL of the SPA to redirect the user to after login
	redirectUri: window.location.origin, // + '/index.html',
	// URL of the SPA to redirect the user after silent refresh
	silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
	// defaults to true for implicit flow and false for code flow
	// as for code code the default is using a refresh_token
	// Also see docs section 'Token Refresh'
	useSilentRefresh: true,
	silentRefreshTimeout: 5000,
	// The SPA's id. The SPA is registerd with this id at the auth-server
	clientId: environment.oauth.client_id,
	// set the scope for the permissions the client should request
	scope: 'openid offline',
	responseType: 'code',
	// oidc: true,
	strictDiscoveryDocumentValidation: false,
	showDebugInformation: true,
	clearHashAfterLogin: true,
	silentRefreshShowIFrame: false
};

