import { Injectable, NgZone } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Subscription, interval } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NewVersionCheckerService {
    isNewVersionAvailable: boolean = false;
    newVersion: string = '';
    newVersionSubscription: Subscription;
    intervalSource = interval(16 * 60 * 1000); // every 15 mins
    intervalSubscription: Subscription;

    constructor(
        private swUpdate: SwUpdate,
        private zone: NgZone
    ) {
        this.checkForUpdate();
    }

    checkForUpdate(): void {
        this.intervalSubscription?.unsubscribe();
        if (!this.swUpdate.isEnabled) {
            return;
        }

        this.zone.runOutsideAngular(() => {
            this.intervalSubscription = this.intervalSource.subscribe(() => {
                try {
                    this.swUpdate.checkForUpdate();
                } catch (error) {
                    console.error('Failed to check for updates:', error);
                }
            })
        });

        this.swUpdate.available.subscribe(update => {
            this.isNewVersionAvailable = true;
            this.newVersion = (update.available.appData as any).version;
        });
    }

    applyUpdate(): void {
        // Reload the page to update to the latest version after the new version is activated
        this.swUpdate.activateUpdate()
            .then(() => document.location.reload())
            .catch(error => console.error('Failed to apply updates:', error));
    }
}