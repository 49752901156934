import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { tap, filter, switchMap } from 'rxjs/operators';


import { AuthService } from './auth.service';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard {

	constructor(private authService: AuthService) { }

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

		console.log('AuthGuard');
		return this.authService.isDoneLoading$.pipe(
			filter(isDone => {
				console.log('isDone', isDone);
				return isDone
			}),
			switchMap(_ => {
				console.log('this.authService.isAuthenticated$', this.authService.isAuthenticated$);
				return this.authService.isAuthenticated$
			}),
			tap(isAuthenticated => {
				console.log('isAuthenticated', isAuthenticated);
				return isAuthenticated || this.authService.login(state.url)
			}),
		);
	}

}
