export namespace DeviceEvents {
	export class FetchForDevice {
		static readonly type = '[DeviceEvents] Fetch all for device';
		constructor(public gateway_id: number, public device_id: any) { }
	}

	export class SetFilter {
		static readonly type = '[DeviceEvents] Set Filter';
		constructor(public device_id: any, public filter: any) { }
	}
}