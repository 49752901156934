export namespace Groups {
	export class FetchGroups {
		static readonly type = '[Groups] Fetch groups';
		constructor() { }
	}

	export class SetFilter {
		static readonly type = '[Groups] Set Filter';
		constructor(public filter: any) { }
	}
}