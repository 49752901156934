import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { environment } from '@env/environment';

import { Select, Store } from '@ngxs/store';
import { OrganizationState } from '../../state/organization/organization.state';
import { Observable } from 'rxjs';
import { Organization } from '../../state/organization/organization.model';
import { UserState } from '../../state/user/user.state';
import { User } from '../../models/user/user.model';

@Injectable({
	providedIn: 'root'
})
export class GatewayService {

	superadmin_prefix: string = '';
	@Select(OrganizationState.getSelecetdOrganization) $organization: Observable<Organization>;
	@Select(UserState.getUser) $user: Observable<User>;

	constructor(private apiService: ApiService, private store: Store) {
		this.$user.subscribe((user: User) => {
			if (!user || Object.keys(user).length == 0) return;

			console.log('user', user);
			if (user.role.id == 1) {
				this.$organization.subscribe((organization: Organization) => {
					this.superadmin_prefix = `/organizations/${organization.id}`;
				});
			}
		});
	}

	fetchGateways(options?: any) {
		return this.apiService.get(`${environment.api_base_url}${this.superadmin_prefix}/gateways`, options);
	}

	fetchGateway(gateway_id: number, options?: any) {
		return this.apiService.get(`${environment.api_base_url}${this.superadmin_prefix}/gateways/${gateway_id}`, options);
	}


	fetchGatewaysList(options?: any) {
		return this.apiService.get(`${environment.api_base_url}${this.superadmin_prefix}/gateways/list`, options);
	}

	fetchGatewaysEvents(options?: any) {
		return this.apiService.get(`${environment.api_base_url}${this.superadmin_prefix}/gateways/events`, options);
	}

	fetchDevicesForGateway(gateway_id: number, options?: any) {
		return this.apiService.get(`${environment.api_base_url}${this.superadmin_prefix}/gateways/${gateway_id}/devices`, options);
	}

	fetchGatewayEvents(gateway_id: number, options?: any) {
		return this.apiService.get(`${environment.api_base_url}${this.superadmin_prefix}/gateways/${gateway_id}/events`, options);
	}

	fetchEventsForGatewayDevice(gateway_id: number, device_id: number, options?: any) {
		return this.apiService.get(`${environment.api_base_url}${this.superadmin_prefix}/gateways/${gateway_id}/devices/${device_id}/events`, options);
	}


	fetchGraphsForGatewayDevice(gateway_id: number, device_id: number, options?: any) {
		return this.apiService.get(`${environment.api_base_url}${this.superadmin_prefix}/gateways/${gateway_id}/devices/${device_id}/graph`, options);
	}

	reinterviewGateway(gateway_id: number, options?: any) {
		return this.apiService.get(`${environment.api_base_url}${this.superadmin_prefix}/gateways/${gateway_id}/reinterview`, options);
	}

	fetchRoomsForGateways(gateway_id: number, options?: any) {
		return this.apiService.get(`${environment.api_base_url}${this.superadmin_prefix}/gateways/${gateway_id}/rooms`, options);
	}
}
