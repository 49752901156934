import { APP_INITIALIZER, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';


// Third Party
import { OAuthModule } from 'angular-oauth2-oidc';
import { AuthConfig } from 'angular-oauth2-oidc';

import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule, NGXS_PLUGINS } from '@ngxs/store';

import { logoutPlugin } from './ngxsLogoutPlugin';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

import { BreadcrumbModule } from 'xng-breadcrumb';

import { ButtonModule } from 'primeng/button';
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { InputTextModule } from 'primeng/inputtext';
import { PickListModule } from 'primeng/picklist';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { PanelModule } from 'primeng/panel';
import { MessagesModule } from 'primeng/messages';
import { SkeletonModule } from 'primeng/skeleton';

//
import { oauthConfig } from './oauth.config';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SharedModule } from './shared/shared.module';
import { TestComponent } from './test/test.component';
import { authAppInitializerFactory } from './auth-app-initializer.factory';
import { AuthService } from './auth.service';
import { environment } from '@env/environment';

// States
import { GatewayState } from './shared/state/gateway/gateway.state';
import { GatewaysEventsState } from './shared/state/gateways_events/gateways_events.state';
import { GatewayEventsState } from './shared/state/gateway_events/gateway_events.state';
import { GatewayDevicesState } from './shared/state/gateway_devices/gateway_devices.state';
import { DeviceEventsState } from './shared/state/device_events/device_events.state';
import { RuleState } from './shared/state/rule/rule.state';
import { UserState } from './shared/state/user/user.state';
import { OrganizationState } from './shared/state/organization/organization.state';
import { GatewayDevicesGraphsState } from './shared/state/gateway_devices_graphs/gateway_devices_graphs.state';
import { OrganizationUsersState } from './shared/state/organization_users/organization_users.state';

import { ProfileSettingsComponent } from './profile-settings/profile-settings.component';
import { GroupsState } from './shared/state/groups/groups.state';
import { ApiErrorState } from './shared/state/api_error/api_error.state';
import { ServiceWorkerModule } from '@angular/service-worker';
import { GroupGatewaysState } from './shared/state/group_gateways/group_gateways.state';


@NgModule({
	declarations: [
		AppComponent,
		DashboardComponent,
		PageNotFoundComponent,
		TestComponent,
		ProfileSettingsComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,
		ButtonModule,
		PaginatorModule,
		TableModule,
		InputTextModule,
		AvatarModule,
		AvatarGroupModule,
		PickListModule,
		InputTextareaModule,
		DialogModule,
		ConfirmDialogModule,
		ToastModule,
		PanelModule,
		MessagesModule,
		SkeletonModule,
		SharedModule,
		OAuthModule.forRoot({
			resourceServer: {
				allowedUrls: [environment.api_base_url],
				sendAccessToken: true
			}
		}),
		FontAwesomeModule,
		BreadcrumbModule,
		environment.production ? [] : NgxsReduxDevtoolsPluginModule.forRoot(),
		NgxsModule.forRoot([GatewayState, GatewaysEventsState, GatewayEventsState, OrganizationState, GatewayDevicesState, DeviceEventsState, GatewayDevicesGraphsState, RuleState, UserState, GroupsState, ApiErrorState, OrganizationUsersState, GroupGatewaysState]),
		NgxsStoragePluginModule.forRoot({
			key: [GatewayState, GatewaysEventsState, GatewayEventsState, OrganizationState, GatewayDevicesState, DeviceEventsState, GatewayDevicesGraphsState, RuleState, UserState, GroupsState, ApiErrorState, OrganizationUsersState, GroupGatewaysState]
		}),
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.production,
			// Register the ServiceWorker as soon as the application is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: 'registerWhenStable:30000'
		})
	],
	providers: [
		{ provide: AuthConfig, useValue: oauthConfig },
		{ provide: APP_INITIALIZER, useFactory: authAppInitializerFactory, deps: [AuthService], multi: true },
		{
			provide: NGXS_PLUGINS,
			useValue: logoutPlugin,
			multi: true
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor(library: FaIconLibrary) {
		library.addIconPacks(fas, far, fab);
	}
}
