import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { HandleApiError } from './api_error.actions';
import { patch, append } from '@ngxs/store/operators';

export class ApiErrorStateModel {
	errors: any
}

@State<ApiErrorStateModel>({
	name: 'apiError',
	defaults: {
		errors: []
	}
})

@Injectable({
	providedIn: 'root'
})
export class ApiErrorState {

	@Selector()
	static error(state: ApiErrorStateModel) {
		return state.errors;
	}

	@Action(HandleApiError)
	getError(ctx: StateContext<ApiErrorStateModel>, action: HandleApiError) {
		ctx.setState(
			patch<ApiErrorStateModel>({
				errors: append([action.error])
			})
		);
	}
}