<div class="mb-4">
	<h1 class="font-semibold text-2xl">
		Dashboard
	</h1>
</div>
<div class="mb-8">
	<div class="before:border-t before:border-secondary before:content-[''] before:absolute before:w-full before:left-0"></div>
	<div class="flex flex-row flex-nowrap py-4">
		<div class="basis-1/4">
			<div class="opacity-50">
				Gateways
			</div>
			<div class="text-2xl font-bold">
				{{ ($organization | async)?.statistics?.gateways?.total || '-' }}
			</div>
		</div>
		<div class="basis-1/4">
			<div class="opacity-50">
				Devices
			</div>
			<div class="text-2xl font-bold">
				{{ ($organization | async)?.statistics?.devices?.total }}
			</div>
		</div>
		<div class="basis-1/4">
			<div class="opacity-50">
				Groups
			</div>
			<div class="text-2xl font-bold">
				-
			</div>
		</div>
		<div class="basis-1/4">
			<div class="opacity-50">
				Counted events today
			</div>
			<div class="text-2xl font-bold">
				{{ ($organization | async)?.statistics?.events?.total || '-' }}
			</div>
		</div>
	</div>
	<div class="before:border-t before:border-secondary before:content-[''] before:absolute before:w-full before:left-0"></div>
</div>
<div class="">
	<h3 class="opacity-50">Health</h3>
	<div class="flex flex-row flex-nowrap py-4">
		<div class="basis-1/3 flex flex-row flex-nowrap gap-4">
			<div class="basis-24">
				<canvas id="online_state_chart" width="96"></canvas>
			</div>
			<div class="flex flex-col justify-center">
				<div class="opacity-50">
					Gateway online state
				</div>
				<div class="text-2xl font-bold">
					{{ ($organization | async)?.statistics?.gateways?.online || '-' }} of {{ ($organization | async)?.statistics?.gateways?.total ||
					'-' }} Gateways are online
				</div>
			</div>
		</div>
		<div class="basis-1/3 flex flex-row flex-nowrap gap-4">
			<div class="basis-24">
				<canvas id="device_online_state_chart" width="96"></canvas>
			</div>
			<div class="flex flex-col justify-center">
				<div class="opacity-50">
					Device online state
				</div>
				<div class="text-2xl font-bold">
					{{ ($organization | async)?.statistics?.devices?.online || '-' }} of {{ ($organization | async)?.statistics?.devices?.total || '-'
					}} Devices are online
				</div>
			</div>
		</div>
	</div>
</div>
<div class="my-4">
	<h1>Gateway events</h1>
	<div class="flex items-center justify-between pb-4">
		<div>

		</div>
		<div>
			<label for="table-search" class="sr-only">Search</label>
			<span class="p-input-icon-left relative">
				<i class="inline-block leading-none absolute -mt-[0.5rem] top-1/2">
					<fa-icon class="inline-block leading-none" [icon]="['fas', 'magnifying-glass']"></fa-icon>
				</i>
				<input pInputText id="table-search" type="text" [(ngModel)]="term" (keyup)="onSearchEvent()" />
			</span>
		</div>
	</div>

	<div class="mb-4 flex items-center gap-2">
		<p-button (onClick)="reloadGatewaysEvents()" [text]="true">
			<fa-icon [icon]="['fas', 'rotate-right']"></fa-icon>
			<span class="ml-2 sr-only">Reload gateways events</span>
		</p-button>
		<div class="ml-auto">
			<p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="($filter | async)?.per_page"
				[totalRecords]="($filter | async)?.total" [rowsPerPageOptions]="rowsPerPageOptions" [showCurrentPageReport]="true"
				currentPageReportTemplate="{first} - {last} of {totalRecords}"></p-paginator>
		</div>
	</div>
	<p-table #table [value]="($gatewaysEventsTableData | async)" (onLazyLoad)="sortChanged($event)" [customSort]="true" sortMode="single"
		[lazy]="true" [lazyLoadOnInit]="false" [loading]="($loading | async )">
		<ng-template pTemplate="loadingicon">
			<fa-icon [icon]="['fas', 'spinner']" [spin]="true"></fa-icon>
		</ng-template>
		<ng-template pTemplate="sorticon" field="col.field" let-sortOrder>
			<fa-icon [icon]="['fas', 'sort']" *ngIf="sortOrder === 0"></fa-icon>
			<fa-icon [icon]="['fas', 'sort-up']" *ngIf="sortOrder === 1"></fa-icon>
			<fa-icon [icon]="['fas', 'sort-down']" *ngIf="sortOrder === -1"></fa-icon>
		</ng-template>
		<ng-template pTemplate="header">
			<tr>
				<th pSortableColumn="gateway">Gateway <p-sortIcon field="gateway"></p-sortIcon></th>
				<th pSortableColumn="device">Device <p-sortIcon field="device"></p-sortIcon></th>
				<th pSortableColumn="old_value">Old value <p-sortIcon field="old_value"></p-sortIcon></th>
				<th pSortableColumn="new_value">New value <p-sortIcon field="new_value"></p-sortIcon></th>
				<th pSortableColumn="type">Type <p-sortIcon field="type"></p-sortIcon></th>
				<th pSortableColumn="created_at">Created at <p-sortIcon field="created_at"></p-sortIcon></th>
			</tr>
		</ng-template>
		<ng-template pTemplate="loadingbody">
			<tr *ngFor="let item of [].constructor(($filter | async)?.per_page)">
				<td *ngFor="let col of columns"><p-skeleton></p-skeleton></td>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-event>
			<tr *ngIf="!($loading | async)">
				<td>
					<a [routerLink]="['/gateways', event.id]" pButton class="p-button-text">
						{{ event.gateway }}
					</a>
				</td>
				<td>{{ event.device }}</td>
				<td>{{ event.old_value }}</td>
				<td>{{ event.new_value }}</td>
				<td>{{ event.type }}</td>
				<td>{{ event.created_at == '-' ? event.created_at : (event.created_at | date:'EEE, MMM d, y, h:mm:ss a') }}</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="emptymessage">
			<tr>
				<td colspan="6">
					<div class="flex flex-col p-3 mb-5 justify-center items-center">
						<fa-icon class="text-[64px] mb-2 opacity-20" [icon]="['fas', 'file-circle-exclamation']"></fa-icon>
						<h1 class="font-semibold text-lg">No Gateways events yet</h1>
					</div>
				</td>
			</tr>
		</ng-template>
	</p-table>
</div>