export namespace GroupGateways {
	export class FetchGroupGateways {
		static readonly type = '[GroupGateways] Fetch Gateways for group';
		constructor(public group_id: any) { }
	}

	export class SetFilter {
		static readonly type = '[GroupGateways] Set Filter';
		constructor(public filter: any) { }
	}
}