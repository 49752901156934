<div>
	<div>
		<label>Timezone</label>
		<select name="timezone">
			<option>UTC</option>
			<option>GTM</option>
		</select>
	</div>
	<div>
		<label>Language</label>
		<select name="language">
			<option>German</option>
			<option>English</option>
		</select>
	</div>
	<div>
		<label>Role</label>
		<span>Administrator</span>
	</div>
</div>