import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

import { ApiService } from '../api/api.service';
import { Rule } from '../../models/rule/rule.model';

@Injectable({
	providedIn: 'root'
})
export class RulesService {

	constructor(private apiService: ApiService) { }

	fetchRules(options?: any) {
		return this.apiService.get(`${environment.api_base_url}/rules`, options)
	}

	addRule(rule: Rule, options?: any) {
		return this.apiService.post(`${environment.api_base_url}/rules`, rule, options);
	}

	updateRule(rule: Rule, id: number, options?: any) {
		return this.apiService.put(`${environment.api_base_url}/rules/${id}`, rule, options);
	}

	activateDeactivateRule(id: number, activate: boolean, options?: any) {
		const activateDeactivate = activate ? 'activate' : 'deactivate';
		return this.apiService.put(`${environment.api_base_url}/rules/${id}/${activateDeactivate}`, null, options);
	}

	deleteRule(id: number, options?: any) {
		return this.apiService.delete(`${environment.api_base_url}/rules/${id}`, options);
	}

}

