<div class="antialiased text-primary" *ngIf="isAuthenticated">
	<app-header></app-header>
	<app-sidebar></app-sidebar>

	<main class="p-4 md:ml-64 h-auto pt-20 relative">
		<!-- <p-button (onClick)="reset()">Reset store</p-button> -->
		<p-messages></p-messages>
		<xng-breadcrumb></xng-breadcrumb>
		<router-outlet></router-outlet>
	</main>
	<p-dialog header="Header" [(visible)]="versionService.isNewVersionAvailable" [modal]="true" [style]="{ width: '50vw' }" [draggable]="false"
		[resizable]="false">
		<ng-template pTemplate="header">
			<span class="">New Version Available</span>
		</ng-template>
		<p class="py-4">
			Version {{ versionService.newVersion }} available. Reload page to get new features!
		</p>
		<ng-template pTemplate="footer">
			<p-button (onClick)="versionService.applyUpdate()">
				<span>Reload</span>
			</p-button>
		</ng-template>
	</p-dialog>
</div>