import { NgIf } from '@angular/common';
import { Directive, OnInit, inject, Input } from '@angular/core';
import { Select } from '@ngxs/store';
import { UserState } from '../../state/user/user.state';
import { Observable } from 'rxjs';
import { User } from '../../models/user/user.model';

@Directive({
  selector: '[isRole]',
  hostDirectives: [NgIf]
})
export class IsRoleDirective implements OnInit {
  private readonly ngIfDirective = inject(NgIf);

  @Input('isRole') role: number | string;
  @Select(UserState.getUser) $user: Observable<User>;

  constructor() { }

  ngOnInit(): void {
    this.$user.subscribe((user: User) => {
      if (user && (user.role?.id == this.role || user.role?.name == this.role)) {
        this.ngIfDirective.ngIf = true;
      } else {
        this.ngIfDirective.ngIf = false;
      }
    });
  }
}
