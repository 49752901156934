import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private apiService: ApiService) { }

  fetchUser(options?: any) {
    return this.apiService.get(`${environment.api_base_url}/whoami`, options);
  }

}
