import { Injectable } from "@angular/core";
import { HttpParams } from '@angular/common/http';

import { Action, Selector, StateContext, State } from "@ngxs/store";
import { GatewayService } from "../../services/gateway/gateway.service";
import { GatewayDevicesGraphs } from "./gateway_devices_graphs.actions";
import { tap, finalize } from "rxjs";


export class GatewayDeviceGrapEntry {
	filter: {
		from: '',
		to: ''
	}
	data: any[]
}

export class GatewayDevicesGraphsStateModel {
	gateway_devices_graphs: { [key: string]: { [key: string]: { [key: string]: { [key: string]: { [key: string]: GatewayDeviceGrapEntry } } } } }
	loading: boolean
}

@State<GatewayDevicesGraphsStateModel>({
	name: 'gatewaydevicesgraphsstate',
	defaults: {
		gateway_devices_graphs: {},
		loading: false
	}
})

@Injectable()
export class GatewayDevicesGraphsState {
	constructor(private gatewayService: GatewayService) { }

	@Selector()
	static getGatewayDevicesGraphs(state: GatewayDevicesGraphsStateModel) {
		return (gateway_id?: number, device_id?: number, component?: string, capability?: string, attribute?: string) => {
			let result: any;
			result = state.gateway_devices_graphs;

			if (gateway_id) result = result[gateway_id];
			if (result && device_id) result = result[device_id];
			if (result && component) result = result[component];
			if (result && capability) result = result[capability];
			if (result && attribute) result = result[attribute];

			return result;
		}
	}

	@Selector()
	static loading(state: GatewayDevicesGraphsStateModel) {
		return state.loading;
	}

	@Action(GatewayDevicesGraphs.FetchForGatewayDevice)
	getGatewayDevices(ctx: StateContext<GatewayDevicesGraphsStateModel>, action: GatewayDevicesGraphs.FetchForGatewayDevice) {
		const state = ctx.getState();
		let filter: any = state.gateway_devices_graphs[action.gateway_id] ? (state.gateway_devices_graphs[action.gateway_id][action.device_id] ? state.gateway_devices_graphs[action.gateway_id][action.device_id]['filter'] : {}) : {};

		filter = {
			...filter,
			from: filter.from ? new Date(filter.from).toISOString() : undefined,
			to: filter.to ? new Date(filter.to).toISOString() : undefined
		}

		const options = {
			params: new HttpParams({
				fromObject: filter
			})
		}

		ctx.patchState({ loading: true });

		return this.gatewayService.fetchGraphsForGatewayDevice(action.gateway_id, action.device_id, options).pipe(tap((returnData: any) => {
			// console.log('Fetch graphs', returnData);

			const data = returnData.data;

			let graphs: any = {};
			// console.log('data', data);
			if (!data) return;
			data.reduce((obj: any, item: any) => {
				if (typeof obj == 'undefined') {
					obj = {};
				}

				if (!obj[item.component]) {
					obj[item.component] = {}
				}

				if (!obj[item.component][item.capability]) {
					obj[item.component][item.capability] = {}
				}

				if (!obj[item.component][item.capability][item.attribute]) {
					obj[item.component][item.capability][item.attribute] = [];
				}

				obj[item.component][item.capability][item.attribute].push(item);

				return obj;

			}, graphs);

			let graphsTree = {
				[action.gateway_id]: {
					[action.device_id]: {
						data: graphs,
						filter: {
							from: '',
							to: ''
						}
					}
				}
			}

			const state = ctx.getState();
			ctx.patchState({
				...state,
				gateway_devices_graphs: {
					...state.gateway_devices_graphs,
					...graphsTree
				}
			});
		}), finalize(() => {
			ctx.patchState({
				loading: false
			});
		}));
	}

	@Action(GatewayDevicesGraphs.SetFilter)
	setFilter(ctx: StateContext<GatewayDevicesGraphsStateModel>, action: GatewayDevicesGraphs.SetFilter) {
		const state = ctx.getState();

		let graphsTree = {}

		if (state.gateway_devices_graphs[action.gateway_id] && state.gateway_devices_graphs[action.gateway_id][action.device_id]) {
			graphsTree = {
				[action.gateway_id]: {
					[action.device_id]: {
						...state.gateway_devices_graphs[action.gateway_id][action.device_id],
						filter: {
							...action.filter
						}
					}
				}
			}
		} else {
			graphsTree = {
				[action.gateway_id]: {
					[action.device_id]: {
						filter: {
							...action.filter
						}
					}
				}
			}
		}

		ctx.patchState({
			...state,
			gateway_devices_graphs: {
				...state.gateway_devices_graphs,
				...graphsTree
			}
		});
	}
}