export namespace GatewayDevices {
	export class FetchForGateway {
		static readonly type = '[GatewayDevices] Fetch all for gateway';
		constructor(public gateway_id: number) { }
	}

	export class SetFilter {
		static readonly type = '[GatewayDevices] Set Filter';
		constructor(public gateway_id: number, public filter: any) { }
	}

	export class UpdateDevice {
		static readonly type = '[GatewayDevices] Update device';
		constructor(public gateway_id: number, public device_id: number, public data: any) { }
	}

	export class UpdateDeviceState {
		static readonly type = '[GatewayDevices] Update device state';
		constructor(public gateway_id: number, public device_id: number, public component: string, public capability: string, public attribute: string, public value: string | number, public created_at: string) { }
	}

	export class UpdateDeviceStateProgress {
		static readonly type = '[GatewayDevices] Update device state progress';
		constructor(public gateway_id: number, public device_id: number, public component: string, public capability: string, public attribute: string, public progress: boolean) { }
	}

}